<template>
  <!-- Main content -->
  <section class="section">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-info">
          <!-- Input Addons -->
          <div class="box-header with-border content">
            <h2 class="box-title">
              Edit Details
            </h2>
            <div class="box-tools pull-right">
              <button type="button" class="button is-danger" @click="deleteAccount">
                <span class="icon"><i class="fas fa-times" /></span> <span>Delete Account</span>
              </button>
            </div>
          </div>
          <div class="box-body">
            <form class="ui form loginForm form-horizontal" @submit.prevent="checkCreds">
              <div class="form-group col-sm-12">
                <label for="name" class="col-sm-2 control-label">Name*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-user" /></span>
                  <input
                    v-model="name"
                    class="form-control"
                    name="name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="username" class="col-sm-2 control-label">Username*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="username"
                    class="form-control"
                    name="username"
                    placeholder="Username"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="displayname" class="col-sm-2 control-label">Display Name</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="displayname"
                    class="form-control"
                    name="displayname"
                    placeholder="Display Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="email" class="col-sm-2 control-label">Email*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-envelope" /></span>
                  <input
                    v-model="email"
                    class="form-control"
                    name="email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                  >
                </div>
              </div>
              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Password<br><small>(blank for no change)</small></label>
                <!-- <div class="input-group">
                      <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                      <input class="form-control" name="password" placeholder="Password (leave blank to not change)" type="password" v-model="password" autocomplete="off">
                    </div> -->
                <password
                  v-model="password"
                  class="col-sm-10 has-text-centered"
                  style="max-width: inherit;"
                  :required="false"
                  @score="showScore"
                  @feedback="showFeedback"
                />
                <small v-for="fb in suggestions" v-if="!warning" class="notification col-sm-12 has-text-centered">💡{{ fb }}</small>
                <p v-if="warning" class="notification is-danger col-sm-12 has-text-centered">
                  ⚠️ {{ warning }}
                </p>
              </div>
              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Region*</label>
                <div class="input-group col-sm-10">
                  <select v-model="locale" class="form-control input-group">
                    <option value="en_AU">
                      🇦🇺 Australia
                    </option>
                    <option value="en_IE">
                      🇮🇪 Ireland
                    </option>
                    <option value="en_NZ">
                      🇳🇿 New Zealand
                    </option>
                    <option value="en_GB">
                      🇬🇧 United Kingdom
                    </option>
                    <option value="en_US">
                      🇺🇸 United States
                    </option>
                  </select>
                  <p><small><i>The region chosen will affect the spelling list language and voice used in our game. This will not change your school's default region.</i></small></p>
                </div>
              </div>

              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Updates*</label>
                <div class="input-group col-sm-10">
                  <b-switch
                    v-model="marketing"
                    type="is-success"
                  >
                    <small v-if="marketing">Yes</small><small v-else>No</small>
                  </b-switch>
                  <p><small><i>Allow us to send emails to you about new features and offers. N.B. We do not share data with third parties.</i></small></p>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div v-if="response" class="notification is-warning col-sm-12">
                    <p><b>Information!</b> {{ response }}</p>
                  </div>
                  <button type="submit" :class="'btn button is-link btn-lg ' + loading">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <!-- errors -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { request } from '@/edshed-common/api'
import Password from 'vue-password-strength-meter'

export default {
  name: 'Account',
  components: {
    Password
  },
  data (router) {
    return {
      section: 'Account',
      loading: '',
      name: this.$store.state.user.name,
      username: this.$store.state.user.username,
      email: this.$store.state.user.email,
      displayname: this.$store.state.user.display_name,
      marketing: parseInt(this.$store.state.user.marketing || 1, 10) === 1,
      password: '',
      response: '',
      locale: this.$store.state.user.locale,
      suggestions: [],
      warning: '',
      score: 0
    }
  },
  methods: {
    showFeedback ({ suggestions, warning }) {
      console.log('🙏', suggestions)
      console.log('⚠', warning)
      this.suggestions = suggestions
      this.warning = warning
    },
    showScore (score) {
      console.log('💯', score)
      this.score = score
    },
    checkCreds () {
      const { name, username, email, password, locale, displayname } = this

      if (name.trim() === '') {
        this.response = 'Name required'
        return
      } else if (username.trim() === '') {
        this.response = 'Username required'
        return
      } else if (email.trim() === '') {
        this.response = 'Email required'
        return
      } else if (name.trim().length < 2) {
        this.response = 'Name must be 2 characters or more'
        return
      } else if (username.trim().length < 2) {
        this.response = 'Username must be 2 characters or more'
        return
      } else if (!this.validateEmail(email.trim())) {
        this.response = 'Email address must be valid'
        return
      } else if (password.trim().length > 0 && (password.trim().length < 7 || this.score < 2 || this.warning !== '')) {
        this.response = 'Password Insecure'
        return
      }

      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')

      let d
      if (password.trim().length > 0) {
        d = { name, username, email, password, locale, displayname }
      } else {
        d = { name, username, email, locale, displayname }
      }

      d.marketing = this.marketing ? 1 : 0

      /* Making API call to authenticate a user */
      request('PUT', 'users/me', d)
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data

          if (data.error) {
            alert(data.error)
            return
          }

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            console.log('user' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }

            this.$store.commit('TOGGLE_LOADING')
            // this.response = 'Details saved'
            this.$buefy.toast.open({ message: 'Details saved', type: 'is-success' })
            this.toggleLoading()
            if (!data.user.email_verified) {
              window.location.replace('https://www.edshed.com/unverified')
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    },
    validateEmail (email) {
      const re = /[^\s@]+@[^\s@]+\.[^\s@]+/
      return re.test(email)
    },
    deleteAccount () {
      if (this.$store.state.user.school.admin) {
        alert('Change your user account to not be an admin or delete your school to delete your user account.')
        return
      }
      const c = confirm('Are you sure you want to delete your user account? All data will be lost and this cannot be undone.')
      if (c) {
        // do delete
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
        request('DELETE', 'users/me', null)
          .then((response) => {
            const data = response.data

            this.toggleLoading()
            this.$store.commit('TOGGLE_LOADING')
            if (data.success) {
              this.$router.push('/logout')
            }
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>
<style>

</style>
